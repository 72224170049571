<template>
  <h1 class="category">
    {{ metacategory }}
  </h1>
  <div class="category-items">
    <button
      v-for="(category, index) in categories"
      :key="index"
      class="btn"
      :class="[
        category.estado === 'activo' ? 'btn-secondary' : 'btn-outline-primary',
      ]"
      @click="toggleCategory(category.id)"
    >
      {{ category.categoria }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    metacategory: {
      type: String,
    },
    categories: {
      type: Array,
    },
    toggleCategory: {
      type: Function,
    },
  },
};
</script>
